<template>
  <div>
    <ManageForm10 />
  </div>
</template>
<script>
import ManageForm10 from "@/components/ManageForm10/ManageForm10";
export default {
  components: {
    ManageForm10,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>